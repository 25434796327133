import React, { FC } from "react";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";
import { useAppState } from "../../context/AppContext";
import { ErrorComponent as ErrorPage } from "../error/ErrorComponent";
import { STRINGS } from "../../utilities/strings";

interface AuthenticationProps {
  children: React.ReactNode;
}

const Authentication: FC<AuthenticationProps> = ({ children }) => {
  const { state } = useAppState();
  const { user } = state;
  console.log("*** AUTH ***");
  let loggedIn = false;

  if (user && user.authenticated) loggedIn = true;

  if (user && user.authenticated === false) {
    console.log("*** Not authenticated: " + user.authenticated);
    if (user.completed) {
      return (
        <ErrorPage
          title={STRINGS.COMPLETED_ERROR}
          alert={STRINGS.COMPLETED_ALERT}
          message={STRINGS.COMPLETE_INFO}
        />
      );
    }

    if (user.notFound) {
      return (
        <ErrorPage
          title={STRINGS.ACCOUNT_NOT_FOUND_ERROR}
          alert={STRINGS.ACCOUNT_NOT_FOUND_ALERT}
          message={STRINGS.CONTACT_REP}
        />
      );
    }

    if (user.expired) {
      return (
        <ErrorPage
          title={STRINGS.EXPIRATION_ERROR}
          alert={STRINGS.EXPIRED_ACCOUNT_ALERT}
          message={STRINGS.CONTACT_REP}
        />
      );
    }

    return (
      <ErrorPage
        title={STRINGS.AUTH_ERROR}
        alert={STRINGS.AUTH_REQUIRED}
        message={STRINGS.CONTACT_REP}
      />
    );
  }
  return loggedIn ? <>{children}</> : <LoadingSpinner />;
};

export default Authentication;
