import styled from "styled-components";
import colorTokens from "@lmig/lmds-tokens/dist/json/core/color.json";

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ToggleShowMore = styled.button`
  display: inline;
  color: ${colorTokens.lmig.color.link.inline.default};
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
