import { initialState, User } from "../../context/AppContext";
import { apiGwService } from "../../services/api-gw-service";

class ValidateApp {
  static instance: ValidateApp;

  static getInstance(): ValidateApp {
    if (!ValidateApp.instance) {
      ValidateApp.instance = new ValidateApp();
    }
    return ValidateApp.instance;
  }

  async init(loginParam: string | null, idParam: string | null) {
    const user: User = initialState.user;
    console.log("+++ login param = " + loginParam);
    if (loginParam) {
      user.loginParam = loginParam;
    }
    console.log("+++ idParam = " + idParam);
    if (idParam) {
      user.idParam = idParam;
    }
    console.log("----- call validate URL API");
    await apiGwService.validate
      .validateUrl(loginParam, idParam)
      .then((apiResp) => {
        user.authenticated = apiResp.valid;
        user.verificationQuestions = apiResp.verificationQuestions;
        user.id = apiResp.id;
        user.expired = apiResp.isExpired;
        user.notFound = apiResp.notFound;
        user.completed = apiResp.isCompleted;
        user.retryCount = apiResp.retryCount;
        user.brand = apiResp.brand;
        console.log(apiResp.isExpired);
        console.log(user.expired);
      });
    return user;
  }
}

export default ValidateApp;
