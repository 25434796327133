import { AxiosResponse } from "axios";
import { axiosPost } from "@lmig/paymenthub-shared-library/utils/axios-utils";
import { User } from "../context/AppContext";
import { environmentProperties } from "../utilities/environment-properties";
import { mapQuestionSetToApiFormat } from "../utilities/map-question-set-to-api";

export const apiGwService = {
  validate: {
    async validateUrl(code: string | null, id: string | null) {
      if (environmentProperties._isLocalHttps()) {
        console.log("=== LOCAL DEV, SKIPPING URL VALIDATION ===");
        return {
          valid: true,
          verificationQuestions: {
            dateOfLoss: null,
            lastFourPhoneDigits: null,
          },
          id: "1234567890",
          brand: "LibertyMutual",
        };
      }
      console.log("=== VALIDATING URL ===");
      let valid: boolean = false;
      let verificationQuestions: { [x: string]: string | null } = {};
      let responseId = "";
      let retryCount: number = 0;
      let expired: boolean = false;
      let notFound: boolean = false;
      let completed: boolean = false;
      let brand = "";
      if (code) {
        try {
          const submitResult: AxiosResponse = await axiosPost(
            "api/confirmAccount",
            { encrypted_string: code, id: id },
          );
          valid = submitResult.data.valid;
          expired = submitResult.data.expired;
          notFound = submitResult.data.notFound;
          completed = submitResult.data.completed;
          verificationQuestions = submitResult.data.questions;
          retryCount = submitResult.data.retryCount;
          responseId = submitResult.data.id;
          brand = submitResult.data.companyBrand;
        } catch (err) {
          console.error(`Error: ${err}`);
          valid = false;
        }
      }
      console.log("==== valid url = " + valid);
      return {
        valid: valid,
        id: responseId,
        verificationQuestions: verificationQuestions,
        retryCount: retryCount,
        isExpired: expired,
        notFound: notFound,
        isCompleted: completed,
        brand,
      };
    },
  },
  validateData: {
    async validateData(user: User | undefined) {
      if (environmentProperties._isLocalHttps()) {
        console.log("=== LOCAL DEV, SKIPPING USER DATA VALIDATION ===");
        return {
          verified: false,
          retryAvailable: false,
        };
      }
      console.log("=== VALIDATING DATA ===");
      let questionSet =
        user?.verificationQuestions &&
        mapQuestionSetToApiFormat(user?.verificationQuestions);
      let id = user?.id;
      let verified = false;
      let retryAvailable = false;
      let retryCount = user?.retryCount;
      let errorOccured = false;
      let payeeAddress;
      try {
        const submitResult: AxiosResponse = await axiosPost(
          "api/validateData",
          { questionSet: questionSet, id: id },
        );
        verified = submitResult.data.isVerified;
        retryAvailable = submitResult.data.isRetryAvailable;
        retryCount = submitResult.data.retryCount;
        payeeAddress = submitResult.data.payeeAddress;
        console.log("==== verified = " + verified);
      } catch (err) {
        console.error(`Error: ${err}`);
        errorOccured = true;
      }
      return {
        verified: verified,
        retryAvailable: retryAvailable,
        retryCount: retryCount,
        errorOccured: errorOccured,
        payeeAddress,
      };
    },
  },
  getUrl: {
    async getUrl(user: User | undefined) {
      if (environmentProperties._isLocalHttps()) {
        console.log("=== LOCAL DEV, SKIPPING IFRAME URL RETRIEVAL ===");
        return "";
      }
      console.log("=== GET URL ===");
      let iframeUrl: string = "";
      let data = JSON.stringify({
        id: user?.id,
      });
      try {
        const submitResult: AxiosResponse = await axiosPost("api/iFrame", data);
        iframeUrl = submitResult.data.url;
        console.log("==== iframeUrl = " + iframeUrl);
      } catch (err) {
        console.error(`=== ERROR GETTING URL ===: ${err}`);
        iframeUrl = "";
      }
      return iframeUrl;
    },
  },
  iframeSubmission: {
    async submit(status: string, id: string | null | undefined) {
      if (environmentProperties._isLocalHttps()) {
        console.log("=== LOCAL DEV, SKIPPING API CALL ===");
        return {
          isSubmissionSuccessful: true,
          errorOccured: false,
        };
      }
      console.log("=== POST URL ===");
      let data = JSON.stringify({
        id: id,
        status: status,
      });
      let isSubmissionSuccessful = false;
      let errorOccured = false;
      try {
        const submitResult: AxiosResponse = await axiosPost(
          "api/iFrameSubmission",
          data,
        );
        isSubmissionSuccessful = submitResult.data.iframeSubmissionSuccessful;
        console.log("==== iFrameSubmission result = " + isSubmissionSuccessful);
      } catch (err) {
        console.error(`Error: ${err}`);
        errorOccured = true;
      }
      return {
        isSubmissionSuccessful: isSubmissionSuccessful,
        errorOccured: errorOccured,
      };
    },
  },
  updateAccountStatus: {
    async updateAccountStatus(status: string, id: string | null | undefined) {
      if (environmentProperties._isLocalHttps()) {
        console.log("=== LOCAL DEV, SKIPPING API CALL ===");
        return {
          id,
          status,
        };
      }
      let data = JSON.stringify({
        id: id,
        status: status,
      });
      let accountUpdatedSuccessfully = false;
      let errorOccurred = false;
      try {
        const updateAccountResponse: AxiosResponse = await axiosPost(
          "api/updateAccountStatus",
          data,
        );
        accountUpdatedSuccessfully =
          updateAccountResponse.data.accountUpdatedSuccessfully;
      } catch (err) {
        console.error(`=== ERROR UPDATING ACCOUNT ===: ${err}`);
        errorOccurred = true;
      }
      return {
        accountUpdatedSuccessfully: accountUpdatedSuccessfully,
        errorOccured: errorOccurred,
      };
    },
  },
};
